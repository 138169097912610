.container {
  /* Empty */
}

.container :global .slick-list,
.container :global .slick-slider,
.container :global .slick-track {
      position: relative;
      display: block;
    }
.container :global .slick-loading .slick-slide,
.container :global .slick-loading .slick-track {
      visibility: hidden;
    }
.container :global .slick-slider {
      box-sizing: border-box;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
.container :global .slick-list {
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
.container :global .slick-list:focus {
      outline: 0;
    }
.container :global .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
    }
.container :global .slick-slider .slick-list,
.container :global .slick-slider .slick-track {
      transform: translate3d(0, 0, 0);
    }
.container :global .slick-track {
      top: 0;
      left: 0;
    }
.container :global .slick-track:after,
.container :global .slick-track:before {
      display: table;
      content: "";
    }
.container :global .slick-track:after {
      clear: both;
    }
.container :global .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
    }
    [dir="rtl"] .slick-slide {
      float: right;
    }
.container :global .slick-slide img {
      display: block;
    }
.container :global .slick-slide.slick-loading img {
      display: none;
    }
.container :global .slick-slide.dragging img {
      pointer-events: none;
    }
.container :global .slick-initialized .slick-slide {
      display: block;
    }
.container :global .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
.container :global .slick-arrow.slick-hidden {
      display: none;
    }
