@import "@7egend/web.core/lib/styles/config";

.item {
    position: relative;
    width: 100%;
    overflow: hidden;
    // border: 1px solid #D8DFE3; //only to test

    .padWrapper {
        width: 100%;

        .pad {
            padding-top: calc(100% / (var(--aspect-ratio)));
        }
    }
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //display: grid;
}

.heightAuto{
    .padWrapper {
        display: none;
    }
}

.contentAuto {
    position: relative;
    width: 100%;
}

.overflowHidden{
    overflow: hidden;
}

// Mixins

/// Create grid dimensions
@mixin generate-spans($splits, $suffix, $i: 1) {
    .span-w#{$i}#{$suffix} {
        grid-column: span $i;
    }

    .span-h#{$i}#{$suffix} {
        grid-row: span $i;
        height: var(--grid-d-#{$i});

        .content {
            height: var(--grid-d-#{$i});
        }
    }

    @include generate-ratio($splits, $suffix, $i);

    @if $i < $splits {
        @include generate-spans($splits, $suffix, ($i + 1));
    }
}

/// Create grid aspect-ratios
@mixin generate-ratio($splits, $suffix, $id, $i: 1) {

    .span-w#{$id}#{$suffix}.span-h#{$i}#{$suffix} {
        @if ($id <= $i) {
            $ratio: $id / $i;
            --aspect-ratio: #{$ratio};
        }

        @if ($id > $i) { // exception to count gaps
            $ratio: ($id + $id - 1) / ($i + $i - 1);
            --aspect-ratio: #{$ratio};
        }
    }

    @if $i < $splits {
        @include generate-ratio($splits, $suffix, $id, ($i + 1));
    }
}

// GENERATE GRID CSS SIZES
@include generate-spans(12, '');

@media #{$tablet} { // Generate span-w and span-h for suffix "-md"
    @include generate-spans(12, "-md");
}

@media #{$phablet} { // Generate span-w and span-h for suffix "-xs"
    @include generate-spans(12, "-xs");
}
