@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.image {
    animation: fadein 0.4s;
    transition: opacity 2.4s ease-in;
}

.masked {
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-origin: original;
    -webkit-mask-clip: initial;

    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-origin: original;
    mask-clip: initial;
}

.placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F2F2F2;
}