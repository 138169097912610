.accordion {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    
    li{
        position: relative;
       
        .title{
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            i{
                align-self: center;
            }

            >div{
                margin: 0;
                padding: 0;
            }
        }

        .content{
            display: flex;
            padding-bottom: 16px;
            max-height: 1200px;
            //close accordion
            transition: max-height 0.15s ease-in,
                        opacity 0.15s 0.15s ease-in,
                        padding-bottom 0.15s ease-in;

            p{
                max-height: 500px;
                margin: 0;
                transition: max-height 0.2s ease-out,
                            margin 0.2s ease-in;
            }

            &.closed, &.closed span{
                opacity: 0;
                max-height: 0;
                padding-bottom: 0;
                //open accordion
                transition: max-height 0.2s 0.1s ease-out,
                            opacity 0.1s ease-in,
                            padding-bottom 0.2s 0.1s ease-in;

                p{
                    max-height: 0;
                    margin: 0;
                    transition: max-height 0.2s 0.1s ease-out,
                                margin 0.2s ease-in;
                }

                tr{
                    height: 0;
                    transition: height 0.2s 0.1s ease-out;
                }
            }
        }
    }
}