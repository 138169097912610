@import "@7egend/web.core/lib/styles/config";

// GRID FOR CONTENT WITH ADJUSTABLE HEIGHT
.gridAuto {
    display: grid;
    grid-gap: 16px;
    grid-auto-rows: auto;
    margin-bottom: 30px;
    min-height: 0;
    min-width: 0;
}

// GRID FOR CONTENT WITH RATIO HEIGHT
.grid {
    display: grid;
    grid-gap: 16px;
    grid-auto-rows: 1fr;
    margin-bottom: 30px;
    min-height: 0;
    min-width: 0;

    @include media(sm) {
        grid-auto-rows: unset;
    }
}

.grid:last-child,
.gridAuto:last-child {
    margin-bottom: 0;
}
