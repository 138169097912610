.container {
	position: relative;
	text-align: center;
	margin-left: auto;
    margin-right: auto;
}

.dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 3px !important;
    background: black;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
        animation-delay: -1.1s;
    }

    &:nth-child(3) {
        margin-right: 0 !important;
        animation-delay: -0.9s;
    } 
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-8px);
	}
}